// krogi start
//console.log(window.innerWidth);
var krogi = document.querySelectorAll(".bg-krog");
var w = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
var polnilnice;
(function () {
    document.getElementsByTagName("body")[0].classList.remove("nojs");
    polnilnice = document.querySelectorAll(".polnilnica");
    
    windowWidth();
    
    setTimeout(function(){
        krogResetMaxWidth(w);
        krogResize();
        initHideMobile(w);
        setTimeout(function(){
            removeTransition();
        }, 1000);
    },50);
})();

function windowWidth() {
    w = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

function removeTransition(){
    krogi.forEach(rmTransition)
}
function rmTransition(item){
    item.style.transition = "unset";
} 

function krogResize() {
    
    let zemljevid = document.querySelector(".zemljevid .map-circle-wrap a iframe");
  
    if (zemljevid){
        if (/iPhone.*/i.test(navigator.userAgent)){
            
            zemljevid.style.height = "400px";
            zemljevid.style.width = "400px";
//            alert("iframe: h: " + zemljevid.height + ", w: " + zemljevid.width /*+ "\r\n"
//                   + "a: h: " + zemljevid.firstElementChild.height + ", w: " + zemljevid.firstElementChild.width + "\r\n"
//                   + "map-circle: h: " + zemljevid.firstElementChild.firstElementChild.height + ", w: " + zemljevid.firstElementChild.firstElementChild.width + "\r\n" */
//            );
//            if(zemljevid.height != zemljevid.width){
//                zemljevid.style.height = zemljevid.width + "px!important";
//                alert("h: " + zemljevid.height + ", w: " + zemljevid.width);
//            }
        }
    }
    
    if (krogi){
        for (let i = 0; i < krogi.length; i++) {
            let krog_width = undefined;
            let krog = krogi[i];

            if(krog.classList.contains(".banner-krog")){
                console.log("krog banner skipped");
            } else {
                krog_width = krog.offsetWidth;
                krog.style.width = krog_width + 75 + "px";
                krog.style.height = krog_width + 75 + "px";
                
                krog.style.padding = (krog_width / 10) + "px";
                krog.style.flexShrink = 0;
                krog.style.maxWidth = "unset";
                krog.style.maxHeight = "unset";
                krog.style.minWidth = "unset";
                krog.style.opacity = 1;
                
                if(krog.classList.contains("kontakt-form")  && w <= 575 ){
                    krog.style.minWidth = 400 + "px";
                    krog.style.minHeight = 400 + "px";
                } else if (krog.classList.contains("kontakt-form")){
                    krog.style.minWidth = 450 + "px";
                    krog.style.minHeight = 450 + "px";
                }
        
            }
        }
    }
}
var banner_krog = document.querySelectorAll(".banner-krog");
function circleResize(){
    if(banner_krog){
        for (let j = 0; j < banner_krog.length; j++){

            let krog_width = undefined;
            let krog = banner_krog[j];
            console.log(krog);
            let parent_krog = krog.parentElement.parentElement;
            console.log(parent_krog);
            parent_krog.style.maxWidth = "";
    
            krog_width = krog.offsetWidth;
            krog.style.maxWidth = "";
            krog.style.height = krog_width + "px";
            let vsebina_kroga = krog.querySelector(".vsebina");
            console.log(vsebina_kroga);
            console.log(vsebina_kroga.offsetHeight > krog.offsetHeight);
            if (vsebina_kroga.offsetHeight > krog.offsetHeight){
                let parent_krog_style = window.getComputedStyle(parent_krog);
                let parent_krog_maxWidth = parent_krog_style.getPropertyValue("max-width");
                loopinner:
                for(let i = 0; i < 10; i++){
                    if (vsebina_kroga.offsetHeight > krog.offsetHeight) {
                        parent_krog_maxWidth = parseInt(parent_krog_maxWidth)
                        parent_krog_maxWidth = parent_krog_maxWidth + 5;
                        parent_krog_maxWidth = parent_krog_maxWidth + "%";
                        parent_krog.style.maxWidth = parent_krog_maxWidth;
                        krog_width = krog.offsetWidth;
                        krog.style.height = krog_width + "px";
                        console.log("krog height: " + krog.offsetHeight);
                        console.log("krog width: " + krog.offsetWidth);
                    } else {
                        break loopinner;
                    }
                }
                
                parent_krog_style = window.getComputedStyle(parent_krog);
                parent_krog_maxWidth = parent_krog_style.getPropertyValue("max-width");
                parent_krog_maxWidth = parseInt(parent_krog_maxWidth)
                parent_krog_maxWidth = parent_krog_maxWidth + 15;
                parent_krog_maxWidth = parent_krog_maxWidth + "%";
                parent_krog.style.maxWidth = parent_krog_maxWidth;
                krog_width = krog.offsetWidth;
                krog.style.height = krog_width + "px";
                krog.style.opacity = 1;

            }
        }
    }
}

function krogRemoveMinWidth(){
    if(krogi){
        krogi.forEach(rmMinWidth);
    }
}
function rmMinWidth(item){
    item.style.minWidth = "unset";
} 

function krogResetMaxWidth(width) {
    if(krogi){
        for (let i = 0; i < krogi.length; i++) {
            let krog = krogi[i];
            if (width <= 350) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "85%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "95%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "105%";
                }
            } else if (width <= 400) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "80%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "90%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "100%";
                }
            } else if (width <= 450) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "70%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "80%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "80%";
                }
    
            } else if (width <= 575) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "50%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "60%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "72%";
                }
    
            } else if (width <= 767) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "55%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "65%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "80%";
                }
    
            } else if (width <= 991) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "45%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "55%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "60%";
                }
            } else if (width <= 1199) {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "60%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "70%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "80%";
                }
            } else {
                if (krog.classList.contains("vsebina-krog__small")) {
                    krog.style.maxWidth = "50%";
                } else if (krog.classList.contains("vsebina-krog__medium")) {
                    krog.style.maxWidth = "60%";
                } else if (krog.classList.contains("vsebina-krog__large")) {
                    krog.style.maxWidth = "70%";
                }
            }
        }
    }
}


window.addEventListener("resize", function () {
    windowWidth();
    krogResetMaxWidth(w);
    krogResize();
    initHideMobile(w);
});

// krogi end

// polnilnice pc start 
function polnilnicaInfo(width){
    if(polnilnice){
        for (let i = 0; i < polnilnice.length; i++){
            polnilnice[i].classList.remove("aktivna");
        }
        let evt = window.event || evt;
        
        evt.target.closest(".polnilnica").classList.add("aktivna")
        let index;
        for (let i = 0; i < polnilnice.length; i++){
            if (polnilnice[i].classList.contains("aktivna")){
                index = i;
            }
        }
        if(width <= 991){
            switch(index){
                case 0: 
                    showPolnilnice();
                    hidePolnilnice(3,4);
                    break;
                case 1: 
                    showPolnilnice();
                    hidePolnilnice(3,4);
                    break;
                case 2:
                    showPolnilnice();
                    hidePolnilnice(0,4);
                    break;
                case 3:
                    showPolnilnice();
                    hidePolnilnice(0,1);
                    break;
                case 4:
                    showPolnilnice();
                    hidePolnilnice(0,1);
                    break;
            }   
        }
    }
}
if (polnilnice){
    for (let i = 0; i < polnilnice.length; i++){
        polnilnice[i].querySelector(".img-wrapper img").addEventListener("click", function (){
            polnilnicaInfo(w)
        });
        polnilnice[i].querySelector(".mini-circle").addEventListener("click", function (){
            polnilnicaInfo(w);
        });
        polnilnice[i].querySelector(".pol-naslov").addEventListener("click", function (){
            polnilnicaInfo(w);
        });
    }
}

// polnilnice pc end

// polnilnice mobitel
var touchableElement = document.querySelector(".polnilnice-parent");
var touchstartX;
var touchstartY;
var touchendX;
var touchendY;
if (touchableElement){
    touchableElement.addEventListener('touchstart', function (event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);
    
    touchableElement.addEventListener('touchend', function (event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handleGesture();
    }, false);
}

function showPolnilnice(){ 
    for (let i = 0; i < polnilnice.length; i++){
        if (polnilnice[i].classList.contains("mobile-hide")){
            polnilnice[i].classList.remove("mobile-hide");
        }
    }
}
function hidePolnilnice(a, b){
    polnilnice[a].classList.add("mobile-hide");
    polnilnice[b].classList.add("mobile-hide");
}

function handleGesture() {
    let index;
    if(polnilnice){

        if (touchendX < touchstartX) {
            //        console.log('Swiped Left');
                    for (let i = 0; i < polnilnice.length; i++){
                        if (polnilnice[i].classList.contains("aktivna")){
                            index = i;
                        }
                        polnilnice[i].classList.remove("aktivna");
                    }
                    //menja aktiven class
                    if(index + 1 > polnilnice.length - 1){ //je zadnji element, ponovno ta postane aktiven
                        polnilnice[index].classList.add("aktivna");
                    } else { //ni zadnji element, class aktiven gre na naslednjega
                        polnilnice[index + 1].classList.add("aktivna");
            
                        switch(index + 1){
                            case 1: 
                                showPolnilnice();
                                hidePolnilnice(3,4);
                                break;
                            case 2:
                                showPolnilnice();
                                hidePolnilnice(0,4);
                                break;
                            case 3:
                                showPolnilnice();
                                hidePolnilnice(0,1);
                                break;
                            case 4:
                                showPolnilnice();
                                hidePolnilnice(0,1);
                                break;
                        }   
                    }
            
                    //skrije elemente, tako da sta na mobitelu vidni le aktiven prejšnji in naslednji
            
                }
            
                if (touchendX > touchstartX) {
            //        console.log('Swiped Right');
                    for (let i = 0; i < polnilnice.length; i++){
                        if (polnilnice[i].classList.contains("aktivna")){
                            index = i;
                        }
                        polnilnice[i].classList.remove("aktivna");
                    }
                    if(index - 1 < 0){
                        polnilnice[index].classList.add("aktivna");
                    } else {
                        polnilnice[index - 1].classList.add("aktivna");
            
                        switch(index -1){
                            case 0:
                                showPolnilnice();
                                hidePolnilnice(3,4);
                                break;
                            case 1: 
                                showPolnilnice();
                                hidePolnilnice(3,4);
                                break;
                            case 2:
                                showPolnilnice();
                                hidePolnilnice(0,4);
                                break;
                            case 3:
                                showPolnilnice();
                                hidePolnilnice(0,1);
                                break;
                        }  
                    }
            
                }
            
                if (touchendY < touchstartY) {
            //        console.log('Swiped Up');
                }
            
                if (touchendY > touchstartY) {
            //        console.log('Swiped Down');
                }
            
                if (touchendY === touchstartY) {
            //        console.log('Tap');
                }
    }
}

//prikaz/skrivanje polnilnic
function initHideMobile(width){
    let index;
    if(polnilnice.length > 0){
        //console.log(polnilnice);
        if (width <= 991){
            //console.log("width <= 991");
            for (let i = 0; i < polnilnice.length; i++){
                if (polnilnice[i].classList.contains("aktivna")){
                    index = i;
                }
            }
            if (index <= 1){
                polnilnice[3].classList.add("mobile-hide");
                polnilnice[4].classList.add("mobile-hide");
            } else if (index == 2){
                polnilnice[0].classList.add("mobile-hide"); //prvi
                polnilnice[4].classList.add("mobile-hide"); //zadnji
    
            } else {
                polnilnice[0].classList.add("mobile-hide");
                polnilnice[1].classList.add("mobile-hide");
            }
        } else {
            for (let i = 0; i < polnilnice.length; i++){
                if (polnilnice[i].classList.contains("mobile-hide")){
                    polnilnice[i].classList.remove("mobile-hide");
                }
            }
        }
    }
}
  
//swipper banner
if(document.querySelector(".rasol-banner")){
    var swiper_banner = new Swiper(".rasol-banner", {
        lazy: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
        
    });
}
  //swiper onas

if(document.querySelector(".onas")){
    var swiper_onas = new Swiper(".onas", {
        slidesPerView: 1,
        spaceBetween: 150,
        autoplay: {
            delay: 6000,
            disableOnInteraction: true,
        },
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
}


( function( $ ) {
    $(document).on("click",'.gumb-vec', function() {
        content_to_open=$(this).attr("aria-label");
        var skupni='#'+content_to_open;
        $(this).removeClass('gumb-vec');
        $(this).addClass('gumb-manj');
        $(skupni).slideToggle('slow');
        $(this).text('Manj');
    });
    $(document).on("click",'.gumb-manj', function() {
        content_to_open=$(this).attr("aria-label");
        var skupni='#'+content_to_open;
        $(this).removeClass('gumb-manj');
        $(this).addClass('gumb-vec');
        $(skupni).slideToggle('slow');
        $(this).text('Več');
    });
    
}( jQuery ) );

//kontakt nepravilni scroll
//menu fix
(function(){
    let url = window.location.href;
    let anchor;
    for (let i = 0; i < url.length; i++){
        if (url[i] == "#"){
            anchor = i;
        }
    }
    url = url.substring(anchor + 1);
    if(typeof anchor !== "undefined"){
        setTimeout(function(){
            document.getElementById(url).scrollIntoView();
            document.getElementsByTagName("body")[0].style.paddingTop = 50 + "px";
        }, 150);
    }
})();



/*
var toTopBtn = document.getElementById("toTopBtn");
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
      toTopBtn.style.display = "block";
    } else {
      toTopBtn.style.display = "none";
    }
}
function toTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
/*
window.location.href = '...';
*/